import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Box, Input } from "@mui/material";

function UploadImgAuto() {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("No file selected");
  const [loading, setLoading] = useState(false);
  const [categoria, setCategoria] = useState([]);

  useEffect(() => {
    const fetchFolders = async () => {
      try {
        const response = await axios.get(
          "https://reuse.wastexrecycling.com/api/categoryContainers/getAll"
        );
        const categories = response.data.map((item) => item.categoria);
        setCategoria(categories);
      } catch (error) {
        console.error("Error fetching folder names:", error);
      }
    };
    fetchFolders();
  }, []);

  const handleCapture = async () => {
    setLoading(true); // Set loading state to true before making the request

    const formData = new FormData();
    formData.append("image", file);
    formData.append("folderName", JSON.stringify(categoria));

    try {
      await axios.post(
        "https://reuse.wastexrecycling.com/api/postsAuto",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      console.log("Image uploaded successfully!");
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setLoading(false); // Set loading state to false after the request completes
    }
  };

  const handleFileInputChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile ? selectedFile.name : "No file selected");
  };

  return (
    <div style={{ textAlign: "center" }}>
      <Input
        type="file"
        accept="image/*"
        onChange={handleFileInputChange}
        style={{ display: "none" }}
        id="file-input"
      />
      <label htmlFor="file-input">
        <Button variant="contained" color="primary" component="span">
          Choose file
        </Button>
      </label>
      <p style={{ marginLeft: "10px" }}>{fileName}</p>

      <Button
        onClick={handleCapture}
        variant="contained"
        color="primary"
        disabled={!file || loading} // Disable button when no file selected or during loading
        style={{ marginTop: "10px", marginLeft: "10px" }}
      >
        {loading ? "Uploading..." : "Upload"}
      </Button>
    </div>
  );
}

export default UploadImgAuto;
