import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { Button, Modal, Box, CircularProgress, Snackbar } from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import MuiAlert from "@mui/material/Alert";

const CameraCaptureAuto = () => {
  const videoRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [capturedBlob, setCapturedBlob] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [categoriaResult, setCategoriaResult] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const openCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: "environment" },
        });

        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      } catch (error) {
        console.error("Error accessing camera:", error);
      }
    };

    openCamera();

    return () => {
      if (videoRef.current) {
        const stream = videoRef.current.srcObject;
        if (stream) {
          const tracks = stream.getTracks();
          tracks.forEach((track) => track.stop());
        }
      }
    };
  }, []);

  const capturePhoto = async () => {
    if (videoRef.current) {
      const canvas = document.createElement("canvas");
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

      return new Promise((resolve) => {
        canvas.toBlob((blob) => {
          resolve(blob);
        }, "image/png");
      });
    }

    return null;
  };

  const handleCapture = async () => {
    const blob = await capturePhoto();

    if (blob) {
      setCapturedBlob(blob);
      const formData = new FormData();
      formData.append("image", blob, "image.png");

      try {
        setLoading(true);
        const response = await axios.post(
          "https://reuse.wastexrecycling.com/api/postsAuto",
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );

        console.log("Photo successfully uploaded!");
        setCategoriaResult(response.data);
        if (response.data === "Unrecognized") {
          setSnackbarMessage("Categoría no reconocida");
        } else {
          setSnackbarMessage("Foto cargada exitosamente");
        }
      } catch (error) {
        console.error("Error uploading photo:", error);
        setSnackbarMessage("Error al subir la foto");
      } finally {
        setLoading(false);
        setSnackbarOpen(true);
      }
    } else {
      console.error("Captured blob is null.");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div style={{ textAlign: "center" }}>
      <video
        ref={videoRef}
        autoPlay
        playsInline
        width="100%"
        height={480}
      ></video>
      <Button
        onClick={handleCapture}
        variant="contained"
        color="primary"
        style={{ marginTop: "10px" }}
      >
        <PhotoCameraIcon />
      </Button>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity={error || !categoriaResult ? "error" : "success"}
          elevation={6}
          variant="filled"
        >
          {error || !categoriaResult
            ? "Error: " + snackbarMessage
            : "Categoría: " + categoriaResult}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default CameraCaptureAuto;
