import React from "react";
import {
  Button,
  Container,
  Grid,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976D2", // Change the primary color
    },
  },
});

const StyledContainer = styled(Container)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
});

const StyledButton = styled(Button)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "15px",
  marginTop: "20px",
  marginBottom: "20px",
});

const HomePage = () => {
  const navigate = useNavigate();

  const handleCameraClick = () => {
    navigate("camara");
  };

  const handleCameraAutoClick = () => {
    navigate("camaraAuto");
  };

  const handleContainersClick = () => {
    navigate("contenedores");
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledContainer>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={handleCameraClick}
            >
              Cámara
            </StyledButton>
          </Grid>
          <Grid item xs={12}>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={handleCameraAutoClick}
            >
              Cámara auto
            </StyledButton>
          </Grid>
          <Grid item xs={12}>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={handleContainersClick}
            >
              Categorías
            </StyledButton>
          </Grid>
        </Grid>
      </StyledContainer>
    </ThemeProvider>
  );
};

export default HomePage;
