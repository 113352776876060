import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Modal, Box, Input, Select, MenuItem } from "@mui/material";

function UploadImg() {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("Ningún archivo seleccionado");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedFolder, setSelectedFolder] = useState("");
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);
  //const [error, setError] = useState(false);
  const [folderOptions, setFolderOptions] = useState([]);

  useEffect(() => {
    const fetchFolders = async () => {
      try {
        const response = await axios.get(
          "https://reuse.wastexrecycling.com/api/categoryContainers/getAll"
        );
        const categories = response.data.map((item) => ({
          categoria: item.categoria,
          bucketReference: item.bucketReference,
        }));
        setFolderOptions(categories);
      } catch (error) {
        console.error("Error al obtener los nombres de las carpetas:", error);
      }
    };
    fetchFolders();
  }, []);

  const submit = async (event) => {
    event.preventDefault();

    // Verificar si se ha seleccionado una imagen
    if (!file) {
      setError1(true);
      return;
    }
    if (!selectedFolder) {
      // Establecer el estado de error a true
      setError2(true);
      return;
    }

    const formData = new FormData();
    formData.append("image", file);
    formData.append("folderName", selectedFolder);

    try {
      // Enviar la foto usando Axios
      await axios.post(
        "https://reuse.wastexrecycling.com/api/posts",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      console.log("¡Foto subida con éxito!");
    } catch (error) {
      console.error("Error al subir la foto:", error);
    }

    // Cerrar el modal después de cargar la imagen
    setIsModalOpen(false);

    setFile(null);
    setFileName("Ningún archivo seleccionado");
    setError1(false);
    setError2(false);
  };

  const handleCapture = async () => {
    // Abrir el modal
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    // Cerrar el modal sin guardar
    setIsModalOpen(false);

    // Restablecer el estado de error
    setError1(false);
    setError2(false);
  };

  const handleFileInputChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    // Actualizar el nombre del archivo
    setFileName(
      selectedFile ? selectedFile.name : "Ningún archivo seleccionado"
    );
  };

  const handleOptionChange = (event) => {
    const selectedCategoria = event.target.value;

    // Encontrar el objeto de la carpeta con la categoría seleccionada
    const selectedFolder = folderOptions.find(
      (folder) => folder.categoria === selectedCategoria
    );

    // Establecer selectedOption en todo el objeto de la carpeta
    setSelectedFolder(selectedFolder.bucketReference || "");
    setSelectedOption(event.target.value);

    // Restablecer el estado de error
    setError1(false);
    setError2(false);
  };

  return (
    <div style={{ textAlign: "center" }}>
      <Input
        type="file"
        accept="image/*"
        onChange={handleFileInputChange}
        style={{ display: "none" }}
        id="file-input"
      />
      <label htmlFor="file-input">
        <Button
          variant="contained"
          color="primary"
          component="span"
          style={{ marginTop: "10px" }}
        >
          Elegir archivo
        </Button>
      </label>
      <p style={{ marginLeft: "10px" }}>{fileName}</p>

      <Button
        onClick={handleCapture}
        variant="contained"
        color="primary"
        style={{ marginTop: "10px", marginLeft: "10px" }}
      >
        Subir
      </Button>

      {isModalOpen && (
        <Modal open={isModalOpen} onClose={handleCloseModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxWidth: "80vw",
              width: "400px",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <h3>Selecciona una categoría:</h3>
            <Select
              value={selectedOption}
              onChange={handleOptionChange}
              fullWidth
              variant="outlined"
              margin="normal"
              style={{ marginBottom: "10px" }}
            >
              {folderOptions.map((folder) => (
                <MenuItem key={folder.categoria} value={folder.categoria}>
                  {folder.categoria}
                </MenuItem>
              ))}
            </Select>
            {error1 && <p style={{ color: "red" }}>Se requiere una imagen</p>}
            {error2 && <p style={{ color: "red" }}>Escoge una opcion</p>}
            <Button
              onClick={submit}
              variant="contained"
              color="primary"
              style={{ marginRight: "10px" }}
            >
              Subir
            </Button>
            <Button
              onClick={handleCloseModal}
              variant="outlined"
              color="secondary"
            >
              Cancelar
            </Button>
          </Box>
        </Modal>
      )}
    </div>
  );
}

export default UploadImg;
