// App.js or your route configuration file
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/home";
import Contenedores from "./pages/containerCategory"; // Import your Contenedores component
import ContainerCategoryById from "./pages/containerCategoryById";
import NavBar from "./pages/navBar";
import UploadImg from "./pages/uploadImg";
import CameraCapture from "./pages/takePhoto"; // Import your Camara component
import CameraCaptureAuto from "./pages/takePhotoAuto";
import UploadImgAuto from "./pages/uploadImgAuto";

const App = () => {
  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route index element={<HomePage />} />
        <Route path="/camara" element={<CameraCapture />} />
        <Route path="/camaraAuto" element={<CameraCaptureAuto />} />
        <Route path="/contenedores" element={<Contenedores />} />
        <Route path="/contenedoresId" element={<ContainerCategoryById />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
