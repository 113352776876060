import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

const FixedButton = ({ onClick }) => {
  return (
    <Button
      onClick={onClick}
      variant="contained"
      color="primary"
      style={{
        position: "fixed",
        bottom: "40px",
        right: "40px",
        borderRadius: "8px",
        padding: "15px",
      }}
    >
      <AddIcon style={{ fontSize: 30 }} />
    </Button>
  );
};

const ContainerCategory = () => {
  const navigate = useNavigate();
  const [containersCategory, setContainersCategory] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [textInput, setTextInput] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    showCategories();
  }, []); // Fetch categories when the component mounts

  const showCategories = async () => {
    try {
      const response = await axios.get(
        "https://reuse.wastexrecycling.com/api/categoryContainers/getAll"
      );
      console.log(response);
      const containers = response.data;
      setContainersCategory(containers);
    } catch (error) {
      console.error("Error getting container categories:", error);
    }
  };

  const refreshCategories = async () => {
    await showCategories();
  };

  const handleClick = (data) => {
    navigate("/contenedoresId", { state: { data } });
  };

  const handleFormToggle = () => {
    setShowForm(!showForm);
  };

  const handleInputChange = (e) => {
    setTextInput(e.target.value);
    setError(null);
  };

  const handleAccept = async () => {
    if (!textInput.trim()) {
      setError("No puede estar vacío");
    } else {
      try {
        await axios.post(
          "https://reuse.wastexrecycling.com/api/categoryContainers/create",
          {
            categoria: textInput,
            bucketReference: textInput,
          }
        );

        onClose();

        // Refresh the list of categories after a new category is created
        refreshCategories();
      } catch (error) {
        console.error("Axios POST request failed:", error);
        setError("Failed to submit. Please try again.");
      }
    }
  };
  const handleClose = () => {
    onClose();
    onModalClose();
  };

  const onClose = () => {
    setShowForm(false);
    setTextInput("");
    setError(null);
  };

  const onModalClose = () => {
    refreshCategories();
  };

  return (
    <>
      <div>
        <h1>Listado de categorías</h1>
      </div>

      {containersCategory.map((category, index) => (
        <Box key={index} mt={2}>
          <Button
            onClick={() => handleClick(category.id)}
            variant="contained"
            color="primary"
            fullWidth
            style={{
              backgroundColor: "transparent",
              border: "1px solid #000",
              borderRadius: "0",
              color: "#000",
            }}
          >
            <h4>{category.categoria}</h4>
          </Button>
        </Box>
      ))}

      <FixedButton onClick={handleFormToggle} />

      <Modal open={showForm} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "80vw",
            width: "400px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <h3>Escribe el nombre de la categoría</h3>
          <TextField
            label="Categoría"
            value={textInput}
            onChange={handleInputChange}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          {error && <p style={{ color: "red" }}>{error}</p>}

          <Grid container spacing={3} justifyContent="center">
            <Grid item>
              <Button
                onClick={handleAccept}
                variant="contained"
                color="primary"
              >
                Aceptar
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleClose}
                variant="outlined"
                color="secondary"
              >
                Cerrar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default ContainerCategory;
