import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Button, Grid, Modal, TextField } from "@mui/material";
import QrCodeIcon from "@mui/icons-material/QrCode";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation, useNavigate } from "react-router-dom";

const FixedButton = ({ onClick }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      style={{
        position: "fixed",
        bottom: "40px",
        right: "40px",
        borderRadius: "8px",
        padding: "15px",
      }}
      //onClick={onClick}
    >
      <QrCodeIcon style={{ fontSize: 30 }} />
    </Button>
  );
};

const ConfirmationModal = ({ isOpen, onCancel, onConfirm }) => {
  return (
    <Modal open={isOpen} onClose={onCancel}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "80vw",
          width: "400px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <h3>
          Si borras la categoría también se borrarán las imágenes y no podrás
          recuperarlas
        </h3>
        <Grid container spacing={3} justifyContent="center">
          <Grid item>
            <Button onClick={onConfirm} variant="contained" color="primary">
              Si
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={onCancel} variant="outlined" color="secondary">
              No
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

const EditModal = ({ isOpen, onClose, onAccept, data }) => {
  const [newCategory, setNewCategory] = useState("");
  const [error, setError] = useState(null);

  const handleAccept = async () => {
    try {
      if (!newCategory.trim()) {
        setError("No puede estar vacío");
        return;
      }

      // Notify the parent component about the accepted changes
      onAccept(newCategory);

      // Make an Axios request to update the category
      await axios.put(
        `https://reuse.wastexrecycling.com/api/categoryContainers/edit?container_id=${data}`,
        { categoria: newCategory }
      );

      // Reset the error when the operation is successful
      setError(null);

      // Close the modal only if there is no error
      onClose();
    } catch (error) {
      console.error("Error updating category:", error);
      // Handle error if needed
    }
  };

  const handleInputChange = (e) => {
    setNewCategory(e.target.value);
    setError(null);
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "80vw",
          width: "400px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <h3>Escribe el nombre de la categoría</h3>
        <TextField
          type="text"
          value={newCategory}
          onChange={handleInputChange}
          placeholder="Categoría"
          style={{ marginBottom: "10px" }}
          fullWidth
          variant="outlined"
          margin="normal"
        />
        {error && <p style={{ color: "red" }}>{error}</p>}
        <Grid container spacing={3} justifyContent="center">
          <Grid item>
            <Button onClick={handleAccept} variant="contained" color="primary">
              Aceptar
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={onClose} variant="outlined" color="secondary">
              Cerrar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

function ContainerCategoryById() {
  const navigate = useNavigate();
  const [imageLinks, setImageLinks] = useState([]);
  const [category, setCategory] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const location = useLocation();
  const data = location.state?.data || "No data";

  const showImg = async () => {
    try {
      const response = await axios.get(
        `https://reuse.wastexrecycling.com/api/categoryContainers/get-container-by-id?container_id=${data}`
      );
      const files = response.data;

      setImageLinks(files.FilesInfo.map((file) => file.imageUrl));
      setCategory(files.categoria);
    } catch (error) {
      console.error("Error getting image links:", error);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        `https://reuse.wastexrecycling.com/api/categoryContainers/delete?container_id=${data}`
      );
      // Redirect to /contenedores after successful delete
      navigate(-1);
    } catch (error) {
      console.error("Error deleting container:", error);
    } finally {
      setShowModal(false); // Close the modal regardless of success or failure
    }
  };

  const handleEditAccept = async (newCategory) => {
    try {
      // Make an Axios request to update the category
      await axios.put(
        `https://reuse.wastexrecycling.com/api/categoryContainers/edit?container_id=${data}`,
        { categoria: newCategory }
      );

      // Update the category state or perform other actions with the new category name
      setCategory(newCategory);
    } catch (error) {
      console.error("Error updating category:", error);
      // Handle error if needed
    } finally {
      setShowEditModal(false); // Close the modal regardless of success or failure
    }
  };

  useEffect(() => {
    showImg();
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1>{category}</h1>
        <div>
          <Button
            onClick={() => setShowEditModal(true)}
            variant="contained"
            color="primary"
            style={{
              padding: "15px",
            }}
          >
            <EditIcon style={{ fontSize: 30 }} />
          </Button>
          <Button
            onClick={() => setShowModal(true)}
            variant="contained"
            color="primary"
            style={{
              marginLeft: "10px", // Add spacing between buttons
              padding: "15px",
            }}
          >
            <DeleteIcon style={{ fontSize: 30 }} />
          </Button>
        </div>
      </div>

      {imageLinks.length > 0 && (
        <div>
          {imageLinks.map((link, index) => (
            <div key={index}>
              <img
                key={index}
                src={link}
                alt={`Image ${index + 1}`}
                style={{ width: "200px", height: "auto", margin: "5px" }}
              />
            </div>
          ))}
        </div>
      )}

      <FixedButton onClick={() => setShowModal(true)} />

      <ConfirmationModal
        isOpen={showModal}
        onCancel={() => setShowModal(false)}
        onConfirm={handleDelete}
      />

      <EditModal
        isOpen={showEditModal}
        onClose={() => setShowEditModal(false)}
        onAccept={handleEditAccept}
        data={data}
      />
    </>
  );
}

export default ContainerCategoryById;
