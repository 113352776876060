import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { Button, Modal, Box, Select, MenuItem } from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

const CameraCapture = () => {
  const videoRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedFolder, setSelectedFolder] = useState("");
  const [folderOptions, setFolderOptions] = useState([]);
  const [error, setError] = useState(false);
  const [capturedBlob, setCapturedBlob] = useState(null);

  useEffect(() => {
    const fetchFolders = async () => {
      try {
        const response = await axios.get(
          "https://reuse.wastexrecycling.com/api/categoryContainers/getAll"
        );
        const categories = response.data.map((item) => ({
          categoria: item.categoria,
          bucketReference: item.bucketReference,
        }));
        setFolderOptions(categories);
      } catch (error) {
        console.error("Error fetching folder names:", error);
      }
    };

    fetchFolders();

    const openCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: "environment" },
        });

        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      } catch (error) {
        console.error("Error accessing camera:", error);
      }
    };

    openCamera();

    return () => {
      // Cleanup code when component unmounts
      if (videoRef.current) {
        const stream = videoRef.current.srcObject;
        if (stream) {
          const tracks = stream.getTracks();
          tracks.forEach((track) => track.stop());
        }
      }
    };
  }, []);

  const capturePhoto = async () => {
    if (videoRef.current) {
      const canvas = document.createElement("canvas");
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

      // Convert the canvas content to a Blob
      return new Promise((resolve) => {
        canvas.toBlob((blob) => {
          resolve(blob);
        }, "image/png");
      });
    }

    return null;
  };

  const handleCapture = async () => {
    const blob = await capturePhoto();

    if (blob) {
      // Save the captured photo
      setCapturedBlob(blob);

      // Open the modal
      setIsModalOpen(true);
    }
  };

  const handleAcceptModal = async () => {
    // Check if an option is selected
    if (!selectedFolder) {
      // Set error state to true
      setError(true);
      return;
    }

    // Close the modal
    setIsModalOpen(false);

    // Reset error state
    setError(false);

    // Create a FormData object and append the Blob
    const formData = new FormData();
    formData.append("image", capturedBlob, "image.png");
    formData.append("folderName", selectedFolder);

    try {
      // Send the photo using Axios
      await axios.post(
        "https://reuse.wastexrecycling.com/api/posts",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      console.log("Photo successfully uploaded!");
    } catch (error) {
      console.error("Error uploading photo:", error);
    }
  };

  const handleOptionChange = async (event) => {
    const selectedCategoria = event.target.value;

    // Find the folder object with the selected categoria
    const selectedFolder = folderOptions.find(
      (folder) => folder.categoria === selectedCategoria
    );

    // Set selectedOption to the entire folder object
    setSelectedFolder(selectedFolder.bucketReference || "");
    setSelectedOption(event.target.value);

    // Reset error state
    setError(false);
  };

  const handleCloseModal = () => {
    // Close the modal without saving
    setIsModalOpen(false);
  };

  return (
    <div style={{ textAlign: "center" }}>
      <video
        ref={videoRef}
        autoPlay
        playsInline
        width="100%"
        height={480}
      ></video>
      <Button
        onClick={handleCapture}
        variant="contained"
        color="primary"
        style={{ marginTop: "10px" }}
      >
        <PhotoCameraIcon />
      </Button>

      {isModalOpen && (
        <Modal open={isModalOpen} onClose={handleCloseModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxWidth: "80vw",
              width: "400px",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <h3>Selecciona una categoría:</h3>
            <Select
              value={selectedOption}
              onChange={handleOptionChange}
              fullWidth
              variant="outlined"
              margin="normal"
              style={{ marginBottom: "10px" }}
            >
              {folderOptions.map((folder) => (
                <MenuItem key={folder.categoria} value={folder.categoria}>
                  {folder.categoria}
                </MenuItem>
              ))}
            </Select>
            {error && <p style={{ color: "red" }}>No puede estar vacío</p>}
            <Button
              onClick={handleAcceptModal}
              variant="contained"
              color="primary"
              style={{ marginRight: "10px" }} // Add margin-right for spacing
            >
              Aceptar
            </Button>
            <Button
              onClick={handleCloseModal}
              variant="outlined"
              color="secondary"
            >
              Cerrar
            </Button>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default CameraCapture;
